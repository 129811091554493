import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Subject, forkJoin, map } from 'rxjs';
import { IPRLineItemViewModel, LookupDetailsViewModel, ServerResponseWithBody } from 'src/app/_models/app.models';
import { IPRItemDetails, ItemTypeResponseData } from 'src/app/_models/pr.models';
import { ItemCatalogue } from 'src/app/_models/pr.models';
import { RestService } from 'src/app/_services/rest.service';
import { getUrlPathFragment } from 'src/app/_static/util';

@Component({
  selector: 'tg-purchase-requisition-line-item-view',
  templateUrl: './purchase-requisition-line-item-view.component.html',
  styleUrls: ['./purchase-requisition-line-item-view.component.scss'],
  providers: [CurrencyPipe]
})
export class PurchaseRequisitionLineItemViewComponent implements OnInit {
  title!: string;
  id!: number;
  data!: IPRItemDetails;
  // unitOfMeasure$ = new BehaviorSubject<LookupDetailsViewModel[]>([]);
  // itemNames$ = new BehaviorSubject<LookupDetailsViewModel[]>([]);
  items: string[] = [];
  itemDetails!: ItemCatalogue[]
  vendorNames!: string[];
  itemTypeResponse!: ItemTypeResponseData[];
  isEdit!: boolean;
  itemIndex!: number;
  itemDetailsForm!: FormGroup;
  isTaxApplicable!: boolean;
  vendorId: number | null = null;

  prLineItemData: Subject<IPRItemDetails> = new Subject();
  constructor(public bsModalRef: BsModalRef, private formBuilder: FormBuilder, private restService: RestService, private currencyPipe: CurrencyPipe) { }

  ngOnInit(): void {
    this.itemDetailsForm = this.createForm();
    if (this.isEdit)
      this.setFormData(this.data);
  }
  setFormData(data: IPRItemDetails) {
    this.getItems(data.itemType, data.itemName);
    this.hsnOrSac.setValue(data.hsn);
    this.itemDetailsForm.patchValue(data)
  }
  getItems(itemType: string, itemName: string) {
    const url = this.vendorId ? getUrlPathFragment('item', itemType, this.vendorId) : getUrlPathFragment('item', itemType);
    this.restService.read<ServerResponseWithBody<ItemTypeResponseData[]>>(url).subscribe(response => {
      this.itemTypeResponse = response.body
      this.items = this.itemTypeResponse.map((item) => item.name);
      this.vendorNames = this.itemTypeResponse.filter((item) => item.name === itemName)[0].item.map((item) => item.vendorName)
    });
  }

  onModalClose() {
    this.bsModalRef.hide();
  }

  loadData() {
    forkJoin([
      this.restService.read
        <ServerResponseWithBody<LookupDetailsViewModel[]>>
        (getUrlPathFragment('itemList')).pipe(map(res => res.body)),
    ]).subscribe(res => {
    })
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      itemMasterId: [null],
      itemType: [{ value: null, disabled: this.isEdit ? true : false }, Validators.required],
      itemName: [{ value: null, disabled: true }, Validators.required],
      vendorName: [{ value: null, disabled: true }, Validators.required],
      unitOfMeasure: [{ value: null, disabled: true }],
      itemDescription: [{ value: null, disabled: this.isEdit ? true : false }],
      quantity: [null, [Validators.required, Validators.min(1)]],
      unitRate: [{ value: null, disabled: true }],
      gstRate: [{ value: null, disabled: true }],
      isChanged: [false],
      customeDeleteId: [null],
      budgetId: [null],
      hsnOrSac: [{ value: null, disabled: true }],
      itemCode: [{ value: null, disabled: true }],
      discount: [null],
      cessAmt: [null],
      stateCessAmt: [null],
      otherCharges: [null]
    })

  }

  get itemType() {
    return this.itemDetailsForm.get('itemType') as FormControl;
  }
  get itemName() {
    return this.itemDetailsForm.get('itemName') as FormControl;
  }
  get vendorName() {
    return this.itemDetailsForm.get('vendorName') as FormControl;
  }
  get unitOfMeasure() {
    return this.itemDetailsForm.get('unitOfMeasure') as FormControl;
  }
  get itemDescription() {
    return this.itemDetailsForm.get('itemDescription') as FormControl;
  }
  get quantity() {
    return this.itemDetailsForm.get('quantity') as FormControl;
  }
  get unitRate() {
    return this.itemDetailsForm.get('unitRate') as FormControl;
  }
  get gstRate() {
    return this.itemDetailsForm.get('gstRate') as FormControl;
  }
  get igstAmount() {
    return this.itemDetailsForm.get('igstAmount') as FormControl;
  }
  get cgstAmount() {
    return this.itemDetailsForm.get('cgstAmount') as FormControl;
  }
  get sgstAmount() {
    return this.itemDetailsForm.get('sgstAmount') as FormControl;
  }
  get hsnOrSac() {
    return this.itemDetailsForm.get('hsnOrSac') as FormControl;
  }
  get itemCode() {
    return this.itemDetailsForm.get('itemCode') as FormControl;
  }
  get discount() {
    return this.itemDetailsForm.get('discount') as FormControl;
  }
  get cessAmt() {
    return this.itemDetailsForm.get('cessAmt') as FormControl;
  }
  get stateCessAmt() {
    return this.itemDetailsForm.get('stateCessAmt') as FormControl;
  }
  get otherCharges() {
    return this.itemDetailsForm.get('otherCharges') as FormControl;
  }

  onItemTypeChange(itemType: string): void {
    this.resetForm();
    this.vendorNames = [];
    this.itemDetails = [];
    this.items = [];
    this.itemName.setValue(null)
    const url = this.vendorId ? getUrlPathFragment('item', itemType, this.vendorId) : getUrlPathFragment('item', itemType);
    this.restService.read<ServerResponseWithBody<ItemTypeResponseData[]>>(url).subscribe(response => {
      this.itemTypeResponse = response.body
      this.items = this.itemTypeResponse.map((item) => item.name);
      this.itemName.setValue(null)
    });
    this.itemName.setValue(null)
    this.itemName.enable();
  }
  onItemChange(name: string) {
    // Reset vendorNames to avoid any previous selection
    this.vendorNames = [];


    // Use setTimeout to re-enable the vendorName control after a short delay

    this.vendorName.enable();
    // Find and update vendorNames based on selected itemName
    const selectedItem = this.itemTypeResponse.find(item => item.name === name);
    if (selectedItem) {
      this.vendorNames = selectedItem.item.map(item => item.vendorName);
      this.itemDetails = selectedItem.item;
    }

    // Patch form values and explicitly set vendorName to null
    this.itemDetailsForm.patchValue({
      vendorName: null, // Reset the vendorName field
      unitOfMeasure: null,
      itemDescription: null,
      quantity: null,
      unitRate: null,
      gstRate: null,
      itemMasterId: null,
      hsnOrSac: null,
      itemCode: null,
      discount: null,
      cessAmt: null,
      stateCessAmt: null,
      otherCharges: null
    });

    this.onVendorChange(this.vendorNames[0]);
  }
  resetForm() {
    this.itemDetailsForm.patchValue({
      itemName: null,
      vendorName: null,
      unitOfMeasure: null,
      itemDescription: null,
      quantity: null,
      unitRate: null,
      gstRate: null,
      itemMasterId: null,
      hsnOrSac: null,
      itemCode: null,
      discount: null,
      cessAmt: null,
      stateCessAmt: null,
      otherCharges: null
    });
  }
  saveItemDetails() {
    this.itemDetailsForm.markAllAsTouched();
    if (this.itemDetailsForm.dirty) {
      this.itemDetailsForm.get('isChanged')?.setValue(true);
    }
    if (this.itemDetailsForm.valid) {
      let itemData: IPRItemDetails = this.itemDetailsForm.getRawValue();
      itemData.hsn = this.hsnOrSac.value;
      itemData.itemIndex = this.itemIndex;
      this.prLineItemData.next(itemData)
      this.bsModalRef.hide();
    }
  }

  onVendorChange(name: string): void {
    const itemDetail = this.itemDetails.filter((item) => item.vendorName === name)[0];
    if (itemDetail) {
      this.itemDetailsForm.patchValue({
        unitOfMeasure: itemDetail.unitOfMeasure,
        itemDescription: itemDetail.description,
        quantity: itemDetail.quantity,
        // unitRate: this.currencyPipe.transform(itemDetail.rate, 'INR')?.replace('₹', ''),
        unitRate: itemDetail.rate,
        gstRate: itemDetail.gstRate,
        itemMasterId: itemDetail.id,
        vendorName: itemDetail.vendorName,
        hsnOrSac: itemDetail.hsnOrSac,
        itemCode: itemDetail.itemCode,
      });
    }
  }
  getTooltipForItem(item: string): string {
    return item;
  }

}
