<div class="flex-between-v-center px-4 py-3 cluster-bar">
    <div class="d-flex flex-column">
        <span class="cluster-title">{{title}}</span>
    </div>

    <i (click)="onModalClose()" class="bi bi-x-lg pointer"></i>
</div>
<div [formGroup]="itemDetailsForm" class="p-3">
    <div class="row">
        <div class="col-4 py-2">
            <label for="itemType" class="tg-form-label form-field__lable">Item Type
                <tg-show-astrisk [control]="itemType"></tg-show-astrisk>
            </label>
            <div class="d-flex gap-4">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="goods" type="radio" formControlName="itemType" name="itemType"
                        value="Goods" (change)="onItemTypeChange('Goods')">
                    <label class="form-check-label tg-form-label" for="goods">Goods</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" id="Service" type="radio" formControlName="itemType" name="itemType"
                        value="Service" (change)="onItemTypeChange('Service')">
                    <label class="form-check-label tg-form-label" for="Service">Service</label>
                </div>
            </div>
            <tg-field-common-validations [control]="itemType"></tg-field-common-validations>
        </div>


        <div class="col-4 py-2  col-md-4 col-lg-4">
            <div class="form-field">
                <label for="itemName" class="tg-form-label form-field__lable">Item Name
                    <tg-show-astrisk [control]="itemName"></tg-show-astrisk>
                </label>
                <div class="form-field__item">
                    <!-- <select id="itemName" class="form-select" formControlName="itemName"
                        (change)="onItemChange($event.target.value)">
                        <option *ngFor="let item of items" [value]="item">{{item}}</option>
                    </select> -->
                    <p-dropdown formControlName="itemName" id="itemName" [options]="items" placeholder="Select an item"
                        class="ui-p-dropdown" (onChange)="onItemChange($event.value)" [filter]="true"
                        [panelStyle]="{'max-width': '200px'}">
                        <ng-template let-item pTemplate="item">
                            <div pTooltip="{{getTooltipForItem(item)}}" tooltipPosition="top">
                                {{ item }}
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <tg-field-common-validations [control]="itemName"></tg-field-common-validations>
                </div>
            </div>
        </div>
        <div class="col-4 py-2 col-md-4 col-lg-4">
            <div class="form-field">
                <label for="vendorName" class="tg-form-label form-field__lable">Vendor Name
                    <tg-show-astrisk [control]="vendorName"></tg-show-astrisk>
                </label>
                <div class="form-field__item">
                    <!-- <select id="vendorName" class="form-select" formControlName="vendorName"
                        (change)="onVendorChange($event.target.value)">
                        <option *ngFor="let vendor of vendorNames" [value]="vendor">{{vendor}}</option>
                    </select> -->
                    <p-dropdown formControlName="vendorName" id="vendorName" placeholder="Select a vendor"
                        [options]="vendorNames" class="ui-p-dropdown" (onChange)="onVendorChange($event.value)"
                        [filter]="true">
                    </p-dropdown>
                    <tg-field-common-validations [control]="vendorName"></tg-field-common-validations>
                </div>
            </div>
        </div>
        <div class="col-4 py-2">
            <div class="form-field">
                <label for="unitOfMeasure" class="tg-form-label form-field__lable">Unit of Measure
                    <tg-show-astrisk [control]="unitOfMeasure"></tg-show-astrisk>
                </label>
                <div class="form-field__item">
                    <input id="unitOfMeasure" class="tg-input" formControlName="unitOfMeasure" type="text" />
                    <tg-field-common-validations [control]="unitOfMeasure"></tg-field-common-validations>
                </div>
            </div>
        </div>


        <div class="col-4 py-2">
            <div class="form-field">
                <label for="itemDescription" class="tg-form-label form-field__lable">Item Description
                    <tg-show-astrisk [control]="itemDescription"></tg-show-astrisk>
                </label>
                <div class="form-field__item">
                    <input id="itemDescription" class="tg-input" formControlName="itemDescription" type="text" />
                    <tg-field-common-validations [control]="itemDescription"></tg-field-common-validations>
                </div>
            </div>
        </div>


        <div class="col-4 py-2">
            <div class="form-field">
                <label for="quantity" class="tg-form-label form-field__lable">Quantity
                    <tg-show-astrisk [control]="quantity"></tg-show-astrisk>
                </label>
                <div class="form-field__item">
                    <input id="quantity" class="tg-input" formControlName="quantity" type="number" />
                    <tg-field-common-validations [control]="quantity"></tg-field-common-validations>
                </div>
            </div>
        </div>


        <div class="col-4 py-2">
            <div class="form-field">
                <label for="unitRate" class="tg-form-label form-field__lable">Unit Rate
                    <tg-show-astrisk [control]="unitRate"></tg-show-astrisk>
                </label>
                <div class="form-field__item">
                    <input id="unitRate" class="tg-input" formControlName="unitRate" type="number" />
                    <tg-field-common-validations [control]="unitRate"></tg-field-common-validations>
                </div>
            </div>
        </div>


        <div class="col-4 py-2">
            <div class="form-field">
                <label for="gstRate" class="tg-form-label form-field__lable">GST In %
                    <tg-show-astrisk [control]="gstRate"></tg-show-astrisk>
                </label>
                <div class="form-field__item">
                    <input id="gstInPercent" class="tg-input" formControlName="gstRate" type="number" />
                    <tg-field-common-validations [control]="gstRate"></tg-field-common-validations>
                </div>
            </div>
        </div>

        <div class="col-4 py-2">
            <div class="form-field">
                <label for="hsnOrSac" class="tg-form-label form-field__lable">HSN
                    <tg-show-astrisk [control]="hsnOrSac"></tg-show-astrisk>
                </label>
                <div class="form-field__item">
                    <input id="hsnOrSac" class="tg-input" formControlName="hsnOrSac" />
                    <tg-field-common-validations [control]="hsnOrSac"></tg-field-common-validations>
                </div>
            </div>
        </div>

        <div class="col-4 py-2">
            <div class="form-field">
                <label for="itemCode" class="tg-form-label form-field__lable">Item Code
                    <tg-show-astrisk [control]="itemCode"></tg-show-astrisk>
                </label>
                <div class="form-field__item">
                    <input id="itemCode" class="tg-input" formControlName="itemCode" />
                    <tg-field-common-validations [control]="itemCode"></tg-field-common-validations>
                </div>
            </div>
        </div>

        <ng-container *ngIf="isTaxApplicable">
            <div class="col-4 py-2">
                <div class="form-field">
                    <label for="discount" class="tg-form-label form-field__lable">Discount
                        <tg-show-astrisk [control]="discount"></tg-show-astrisk>
                    </label>
                    <div class="form-field__item">
                        <input id="discount" class="tg-input" formControlName="discount" type="number" />
                        <tg-field-common-validations [control]="discount"></tg-field-common-validations>
                    </div>
                </div>
            </div>

            <div class="col-4 py-2">
                <div class="form-field">
                    <label for="cessAmt" class="tg-form-label form-field__lable">Cess Amount
                        <tg-show-astrisk [control]="cessAmt"></tg-show-astrisk>
                    </label>
                    <div class="form-field__item">
                        <input id="cessAmt" class="tg-input" formControlName="cessAmt" type="number" />
                        <tg-field-common-validations [control]="cessAmt"></tg-field-common-validations>
                    </div>
                </div>
            </div>

            <div class="col-4 py-2">
                <div class="form-field">
                    <label for="stateCessAmt" class="tg-form-label form-field__lable">State Cess Amount
                        <tg-show-astrisk [control]="stateCessAmt"></tg-show-astrisk>
                    </label>
                    <div class="form-field__item">
                        <input id="stateCessAmt" class="tg-input" formControlName="stateCessAmt" type="number" />
                        <tg-field-common-validations [control]="stateCessAmt"></tg-field-common-validations>
                    </div>
                </div>
            </div>

            <div class="col-4 py-2">
                <div class="form-field">
                    <label for="otherCharges" class="tg-form-label form-field__lable">Other Charges
                        <tg-show-astrisk [control]="otherCharges"></tg-show-astrisk>
                    </label>
                    <div class="form-field__item">
                        <input id="otherCharges" class="tg-input" formControlName="otherCharges" type="number" />
                        <tg-field-common-validations [control]="otherCharges"></tg-field-common-validations>
                    </div>
                </div>
            </div>
        </ng-container>



    </div>

    <div class="flex-vh-center">
        <button class="save-btn" (click)="saveItemDetails()">Save Item</button>
    </div>
</div>