import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { ILookupModel, IReportStatusBase, QueueFilterViewModel, ServerResponseWithBody } from 'src/app/_models/app.models';
import { getUrlPathFragment } from 'src/app/_static/util';
import { catchError, filter, forkJoin, map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QueueFilterService {

  private allReportStatuses: string[] = [];
  private displayVoucherStatusOptions: string[] = [];
  private filterValues: string[] = [];
  private filterMappings: Map<string, string> = new Map<string, string>();


  constructor(private restService: RestService) { }

  init(): Observable<any> {
    return forkJoin({
      filters: this.fetchFilters(),
      reportStatuses: this.fetchReportStatuses()
    })
      .pipe(
        tap(({ filters, reportStatuses }) => {
          this.resetFilters();
          this.setFilters(filters);
          this.setReportStatuses(reportStatuses);
        })
      )
  }

  private resetFilters() {
    this.allReportStatuses = [];
    this.displayVoucherStatusOptions = [];
    this.filterValues = [];
    this.filterMappings = new Map<string, string>();

  }

  private fetchFilters(): Observable<ILookupModel[]> {
    return this.restService.read<ServerResponseWithBody<ILookupModel[]>>(getUrlPathFragment('lookup', 'SearchParametersQueue'))
      .pipe(
        map(i => i.body)
      )
  }

  private fetchReportStatuses(): Observable<ILookupModel[]> {
    return this.restService.read<ServerResponseWithBody<ILookupModel[]>>(getUrlPathFragment('lookup', 'ReportStatus'))
      .pipe(
        map(i => i.body)
      )
  }

  // Public  services
  initPage(status?: string): QueueFilterViewModel {
    return <QueueFilterViewModel>{
      page: 1,
      pageSize: 5,
      searchString: "",
      searchCriteria: this.initSearchCriteria(status),
      sortByColumnName: null,
      sortOrder: 1,
    }
  }

  generateEmptyQueueFilterViewModel(): QueueFilterViewModel {
    return <QueueFilterViewModel>{
      "page": 0,
      "pageSize": 0,
      searchString: "",
      searchCriteria: {},
      sortByColumnName: null,
      sortOrder: 1,
    }
  }


  handleQueueFiltration<T extends IReportStatusBase>(filter: string, subjects: T[]): T[] {
    switch (filter) {
      case 'Regular': {
        return subjects.filter((r) => r.reportStatus !== 'REVOKED');
      }
      case 'Draft': {
        return subjects.filter((r) => r.reportStatus !== 'DRAFT');
      }

      case 'Submitted': {
        return subjects.filter((r) => r.reportStatus !== 'SUBMITTED');
      }

      case 'Accepted': {
        return subjects.filter((r) => r.reportStatus !== 'ACCEPTED');
      }

      case 'Sent Back': {
        return subjects.filter((r) => r.reportStatus !== 'SENT_BACK');
      }

      case 'Discarded': {
        return subjects.filter((r) => r.reportStatus !== 'REVOKED');
      }

      case 'Paid': {
        return subjects.filter((r) => r.reportStatus !== 'PAID');
      }

      case 'Posted': {
        return subjects.filter((r) => r.reportStatus !== 'POSTED_TO_DESTINATION_SYSTEM');
      }
      default: {
        return subjects;
      }
    }
  }

  // Internal helper functions

  private initSearchCriteria(filter?: string): Record<string, string[]> {
    const status = filter ?? 'Regular';
    return <Record<string, string[]>>{
      "reportStatus": this.getStatusValueMapping(status)
    }
  }

  getStatusValueMapping(value: string): string[] {
    let nullSafeStatusMapping: string = value ?? '';
    switch (nullSafeStatusMapping) {
      case 'Regular': {
        return this.allReportStatuses.filter((s) => s !== 'REVOKED')
      }
      case 'Draft': {
        return ['DRAFT'];
      }
      case 'Submitted': {
        return ['SUBMITTED'];
      }
      case 'Accepted': {
        return ['ACCEPTED'];
      }
      case 'Sent Back': {
        return ['SENT_BACK'];
      }
      case 'Discarded': {
        return ['REVOKED'];
      }
      case 'Paid': {
        return ['PAID'];
      }
      case 'Posted': {
        return ['POSTED_TO_DESTINATION_SYSTEM'];
      }
      case 'All': {
        return this.allReportStatuses;
      }
      default: {
        return [value];
      }
    }
  }


  private setFilters(filters: ILookupModel[]) {
    let errors: string[] = [];

    filters.forEach((f: ILookupModel) => {
      // Sanity checks
      if (!f.type) {
        errors.push("A lookup type was found to be null");
      }
      if (!f.value) {
        errors.push("A lookup value was found to be null");
      }
      if (!f.attribute) {
        errors.push("A lookup attribute was found to be null");
      }
    })

    if (errors.length > 0) {
      throw new Error(errors.join('\t'));
    }

    // Populate properties only if there are no errors
    filters.forEach((f: ILookupModel) => {
      this.filterValues.push(f.value);
      this.filterMappings.set(f.value, f.attribute);
    });

  }

  private setReportStatuses(reportStatuses: ILookupModel[]) {
    reportStatuses.forEach(r => {
      this.displayVoucherStatusOptions.push(r.value);
      this.allReportStatuses.push(r.attribute);
    })
  }



  // Public methods to get the data
  getAllReportStatuses(): string[] {
    return this.allReportStatuses;
  }

  getDisplayVoucherStatusOptions(): string[] {
    return this.displayVoucherStatusOptions;
  }

  getFilterValues(): string[] {
    return this.filterValues;
  }

  getFilterMappings(): Map<string, string> {
    return this.filterMappings;
  }
  private initSearchCriteriaBudgetDrillDown(filterStatus?: string, filterType?: string): Record<string, string[]> {
    const status = filterStatus ?? 'Regular';  // Fixed variable usage
    return {
      "reportStatus": this.getStatusValueMapping(status),  // Added missing comma
      "hasPrecedingDocument": filterType == null ? [] : [filterType]  // Ensured type matches Record<string, string[]>
    };
  }

  initPageBudgetDrillDown(status?: string, type?: any): QueueFilterViewModel {
    return {
      page: 1,
      pageSize: 5,
      searchString: "",
      searchCriteria: this.initSearchCriteriaBudgetDrillDown(status, type),  // Correctly passed parameters
      sortByColumnName: null,
      sortOrder: 1,
    };
  }
}
