import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../../_services/auth.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs';

@Component({
  selector: 'tg-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss'],
})
export class TopNavbarComponent implements OnInit {
  items: MenuItem[] = [];
  bsModalRef?: BsModalRef;
  devMode = environment.devMode;

  adminMenuItems: MenuItem[] = [
    {
      label: 'Policy',
      icon: 'pi pi-cog',
      routerLink: '/admin/expense-metadata',
    },
    {
      label: 'Approver',
      icon: 'pi pi-users',
      routerLink: '/admin/approver',
    },
    {
      label: 'Location',
      icon: 'pi pi-map-marker',
      routerLink: '/admin/location-master',
    },
    {
      label: 'Delegations',
      icon: 'pi pi-reply',
      routerLink: '/delegation/admin',
    },
    {
      label: 'Report Queue',
      icon: 'pi pi-list',
      routerLink: '/queue/admin',
    },
    // {
    //   label: 'Entitlement',
    //   icon: 'pi pi-briefcase',
    //   routerLink: 'entitlement/admin-entitlement',
    // },
    {
      label: 'CEM Master Data',
      icon: 'pi pi-th-large',
      items: [
        {
          label: 'Branch Master',
          routerLink: '/cem/branch',
        },
        {
          label: 'Cost Center Master',
          routerLink: '/cem/cost-center',
        },
        {
          label: 'Department Master',
          routerLink: '/cem/department',
        },
        {
          label: 'Employee Master',
          routerLink: '/cem/employee-master',
        },
        {
          label: 'Employee Grade Master',
          routerLink: '/cem/employee-grade',
        },
        {
          label: 'Employee Type Master',
          routerLink: '/cem/employee-type',
        },
      ],
    },
  ];

  budgetMenuItems: MenuItem[] = [
    {
      label: 'View All Budgets',
      icon: 'bi bi-bag-plus',
      routerLink: '/budget',
    },
    {
      label: 'Create New Budget',
      icon: 'bi bi-wallet2',
      routerLink: '/budget/create',
    },
    // {
    //   label: 'View Budget Details',
    //   icon: 'bi bi-clipboard-data',
    //   routerLink: '/budget/details',
    // }
  ];

  masterMenuItems: MenuItem[] = [
    {
      label: 'View All Masters',
      icon: 'pi pi-cog',
      routerLink: '/master',
    },
    {
      label: 'Create New Master',
      icon: 'pi pi-users',
      routerLink: '/master/create',
    },
  ]

  showSidebar = false;
  username = '';

  constructor(
    private modalService: BsModalService,
    public authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.getAuth().subscribe((v) => {
      this.items = [
        {
          label: 'Profile',
          icon: 'pi pi-user',
          command: () => {
            this.router.navigate(['/']);
          },
        },
        {
          label: 'Entitlement',
          icon: 'pi pi-briefcase',
          command: () => {
            this.router.navigate(['/entitlement']);
          },
        },
        {
          label: 'Logout',
          icon: 'pi pi-sign-out',
          command: () => {
            this.authService.logout();
          },
        },
      ];

      let parts = v?.sub.split('@') ?? [];
      this.username = parts[0]?.replace('.', ' ') ?? '';
    });
  }
}
