import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HandleUndefinedPipe } from '../_pipes/handle-undefined.pipe';
import { DragAndDropFileUploadDirective } from './drag-and-drop-file-upload.directive';
import { EllipsisPipe } from "../_pipes/ellipsis.pipe";
import { FilterPipe } from '../_pipes/filter.pipe';
import { FieldCommonValidationsComponent } from '../common-components/field-common-validations/field-common-validations.component';
import { ShowAstriskComponent } from '../common-components/show-astrisk/show-astrisk.component';
import { StatisticsBarComponent } from '../common-components/statistics-bar/statistics-bar.component';
import { ApprovalFieldsComponent } from '../common-components/approval-fields/approval-fields.component';
import { OrgTreeBuilderComponent } from '../common-components/org-tree-builder/org-tree-builder.component';
import { PrimengModule } from '../primeng/primeng.module';
import { ItemDetailsComponent } from '../common-components/item-details/item-details.component';
import { WorkflowComponent } from '../common-components/workflow/workflow.component';
import { DocumentQueueComponent } from '../common-components/document-queue/document-queue.component';
import { DocumentUploadComponent } from '../common-components/document-upload/document-upload.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PurchaseRequisitionLineItemViewComponent } from '../components/purchase-requisition/purchase-requisition-line-item-view/purchase-requisition-line-item-view.component';
import { FilterDropdownPipe } from '../_pipes/filter-dropdown';
import { LoginLeftPanelComponent } from '../common-components/login-left-panel/login-left-panel.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AmountShortenerPipe } from '../_pipes/amount-shortener.pipe';
import { BudgetSummaryComponent } from '../common-components/budget-summary/budget-summary.component';
const COMPONENTS = [
  HandleUndefinedPipe,
  DragAndDropFileUploadDirective,
  EllipsisPipe,
  FilterPipe,
  FieldCommonValidationsComponent,
  ShowAstriskComponent,
  StatisticsBarComponent,
  ApprovalFieldsComponent,
  OrgTreeBuilderComponent,
  ItemDetailsComponent,
  WorkflowComponent,
  DocumentQueueComponent,
  DocumentUploadComponent,
  PurchaseRequisitionLineItemViewComponent,
  FilterDropdownPipe,
  LoginLeftPanelComponent
]
@NgModule({
  declarations: [...COMPONENTS, AmountShortenerPipe, BudgetSummaryComponent],
  imports: [CommonModule, PrimengModule, FormsModule, ReactiveFormsModule, RadioButtonModule],
  exports: [...COMPONENTS, AmountShortenerPipe, BudgetSummaryComponent],
})
export class SharedModule { }
